.hui-banner {
  @apply tw-relative
    tw-isolate
    tw-flex
    tw-items-center
    tw-overflow-hidden
    tw-mb-6
    tw-rounded-md
    sm:before:tw-flex-1;

  &-content {
    @apply tw-w-full
    tw-py-4
    tw-pl-4
    sm:tw-pl-7
    sm:tw-py-4;
  }

  .dismiss-button-wrapper {
    @apply tw-flex
      tw-flex-1
      tw-ml-8
      tw-pl-4;

    .dismiss-button {
      @apply tw-font-semibold
        tw-rounded-full
        tw-w-7
        tw-h-7
        tw-absolute
        tw-top-2
        tw-right-2
        tw-mx-auto
        tw-my-0
        active:tw-bg-gray-300;

      svg {
        @apply tw-text-brand-700;
      }
    }
  }

  &--dark {
    .dismiss-button-wrapper {
      .dismiss-button {
        @apply active:tw-bg-gray-400;

        svg {
          @apply tw-text-white;
        }
      }
    }
  }
}
