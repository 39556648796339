.hnry-button {
  $self: &;

  &--tertiary {
    @apply tw-text-brand-600
      tw-bg-white
      tw-ring-1 
      tw-ring-inset
      tw-ring-gray-300
      hover:tw-bg-gray-50
      hover:tw-text-gray-800
      disabled:tw-bg-white/disabled
      disabled:tw-text-brand-700/disabled
      disabled:hover:tw-text-gray-800/disabled;

    svg, i.fa, img {
      @apply tw-text-brand-600;
    }
    
    &:disabled {
      svg, i.fa, img {
        @apply tw-text-gray-800/disabled;
      }
    }

    &.hnry-button--loading {
      @apply 
        hover:tw-bg-white
        after:tw-text-brand-600;
    }

    &#{$self}--invert {
      @apply 
        tw-bg-brand-700
        tw-ring-brand-500
        tw-text-white
        hover:tw-bg-brand-900
        disabled:tw-text-white/disabled
        disabled:tw-bg-brand-600/disabled;

      &#{$self}--loading {
        @apply hover:tw-bg-brand-700
          after:tw-text-white;
      }

      svg, i.fa, img {
        @apply tw-text-white;
      }

      &:disabled {
        svg, i.fa, img {
          @apply tw-text-white/disabled;
        }
      }
    }
  }
}