.hnry-badge {
  @apply tw-rounded-md
    tw-py-1
    tw-px-2
    tw-text-xs
    tw-inline-block
    tw-font-normal;

  &--green,
  &--success {
    @apply tw-bg-green-50
    tw-text-green-700;
  }

  &--warning,
  &--amber {
    @apply tw-bg-amber-50
      tw-text-amber-700;
  }

  &--info,
  &--blue {
    @apply tw-bg-blue-50
      tw-text-blue-700;
  }

  &--red,
  &--error {
    @apply tw-bg-red-50
      tw-text-red-700;
  }

  &--admin {
    @apply tw-bg-admin-500
      tw-text-white;
  }

  &--burple {
    @apply tw-bg-brand-25
      tw-text-brand-400;
  }

  &--gray {
    @apply tw-bg-gray-100
      tw-text-gray-700;
  }

  &--orange {
    @apply tw-bg-brand-orange-50
      tw-text-brand-orange-950;
  }

  &--pink {
    @apply tw-bg-brand-pink-50
      tw-text-brand-pink-950;
  }
}
