.hnry-heading {
  @apply tw-font-semibold
    tw-text-gray-900;

  &--h1 {
    @apply tw-text-lg;
  }

  &--h2 {
    @apply tw-text-base;
  }

  &--h3 {
    @apply tw-text-sm;
  }

  &--marketing-h1 {
    @apply tw-text-2xl;
  }

  &--marketing-h2 {
    @apply tw-text-xl;
  }
}
