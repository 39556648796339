.hui-select {
  &__wrapper {
    @apply tw-relative;
  }

  &__button {
    @apply tw-h-10
      tw-w-full
      tw-rounded-md
      tw-bg-white
      tw-py-1.5
      tw-px-3
      tw-text-left
      tw-text-gray-700
      tw-shadow-sm
      tw-ring-1
      tw-ring-inset
      tw-ring-gray-300
      disabled:tw-bg-gray-50
      disabled:tw-ring-gray-200
      disabled:tw-cursor-not-allowed
      sm:tw-text-sm
      sm:tw-leading-6
      tw-duration-300;
    @apply focus:tw-ring-1
      focus:tw-ring-inset
      focus:tw-ring-brand-200
      focus:tw-outline-none
      focus:tw-border-b-0 #{!important};

    span {
      @apply tw-inline-block
        tw-truncate;
    }

    &[data-state="open"] {
      @apply tw-shadow-lg;

      svg {
        @apply tw-transform
          tw-rotate-180;
      }
    }
  }

  &__label {
    @apply tw-block
      tw-truncate;
  }

  &__icon {
    @apply tw-p-1;
  }

  &__options {
    // old modal has z-index of 1050
    @apply tw-absolute
      tw-text-gray-700
      tw-mt-1
      tw-z-[1051]
      tw-w-full
      tw-max-h-32
      sm:tw-max-h-48
      tw-overflow-y-auto
      tw-rounded-md
      tw-py-1
      tw-bg-white
      tw-text-base
      tw-shadow-lg
      tw-ring-1
      tw-ring-gray-300
      tw-ring-opacity-5
      focus:tw-border-brand-400
      focus:tw-outline-none
      sm:tw-text-sm;
  }

  &__option {
    @apply tw-text-gray-700
      tw-m-0.5
      tw-py-2
      tw-px-3
      tw-font-light
      hover:tw-bg-gray-100
      tw-text-ellipsis
      tw-cursor-pointer
      focus:tw-border-brand-400;

    &[data-state="checked"] {
      @apply tw-bg-gray-200;
    }

    &[data-disabled=""] {
      @apply tw-text-gray-400 tw-line-through tw-cursor-not-allowed;
    }
  }
}
