.otp-input-wrapper {
  @apply tw-w-full;

  .otp-input {
    @apply tw-font-sans
      tw-rounded-md
      tw-text-center
      tw-tracking-normal
      tw-box-border
      tw-border-solid
      tw-border
      tw-border-gray-300
      tw-h-14
      sm:tw-mx-1
      sm:tw-p-2
      sm:tw-h-16
      focus-visible:tw-outline-none
      focus:tw-border-brand-green
      active:tw-border-brand-green
      placeholder:tw-text-gray-200;
    @apply tw-w-9
      xs:tw-w-11
      sm:tw-w-14
      tw-text-gray-900
      tw-text-lg
      sm:tw-text-4xl #{!important};
  }

  .otp-input-divider {
    @apply tw-text-lg
      sm:tw-text-4xl
      tw-font-extrabold
      tw-text-gray-300;
  }
}
