.hui-table-icon {
  @apply tw-text-blue
    tw-w-5
    tw-h-5;

  &--danger {
    @apply tw-text-red;
  }

  &--amber {
    @apply tw-text-amber;
  }

  &--gray {
    @apply tw-text-gray;
  }
}
