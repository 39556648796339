.hnry-menu-item-link {
  @apply tw-items-center tw-px-4 tw-py-3 tw-text-sm;

  // need to overwrite the a tag text color on hnry-table class
  @apply tw-flex
    hover:tw-bg-gray-200
    active:tw-text-gray-950
    focus:tw-text-gray-950
    tw-text-gray-950
    tw-w-full #{!important};

  svg {
    @apply tw-mr-3;
  }

  &.active {
    @apply tw-bg-gray-200;
  }
}
