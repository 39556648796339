.animated-background {
  &-setup {
    background: linear-gradient(-45deg, #dbeafe,#dbeafe, #4FA7CF, #E172A1, #e06128, #E9986A, #F9D44C, #33B082, #4FA7CF, #dbeafe, #dbeafe);
    background-size: 1000% 400%;
  }

  &-run {
    animation: gradient 3s ease 0s 1 normal forwards;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  
  100% {
    background-position: 100% 50%;
  }
}