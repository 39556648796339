.hnry-button {
  $self: &;

  &--primary {
    @apply tw-bg-brand-700
      tw-bg-gradient-to-b
      tw-from-brand-900
      tw-from-10%
      tw-to-[#0C105A]
      hover:tw-from-[#020428]
      hover:tw-to-[#030534]
      hover:tw-text-white
      disabled:tw-bg-brand/disabled
      disabled:tw-bg-none
      disabled:tw-text-brand-50/disabled;

    svg, i.fa, img {
      @apply tw-text-white;
    }

    &:disabled {
      svg, i.fa, img {
        @apply tw-text-brand-50/disabled;
      }
    }

    &#{$self}--loading {
      @apply 
        hover:tw-from-brand-900
        hover:tw-to-[#0C105A]
        after:tw-text-white;

    }

    &#{$self}--invert {
      @apply tw-bg-none
        tw-bg-white
        tw-text-brand-700
        disabled:tw-text-brand-700/disabled
        disabled:tw-bg-white/disabled
        disabled:hover:tw-bg-white/disabled
        hover:tw-bg-brand-50;

      &#{$self}--loading {
        @apply hover:tw-bg-white;

        &::after {
          @apply tw-text-brand-700;
        }
      }

      svg, i.fa, img {
        @apply tw-text-brand-700;
      }

      &:disabled {
        svg, i.fa, img {
          @apply tw-text-brand-700/disabled;
        }
      }
    }
  }
}
