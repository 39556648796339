.hnry-button {
  $self: &;

  &#{$self}--admin {
    @apply tw-bg-admin-500
      tw-text-white
      focus:tw-outline-offset-2
      focus-visible:tw-outline-admin-400
      hover:tw-text-white
      hover:tw-bg-admin-700;
  }
}