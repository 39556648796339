.hui-alert {
  @apply tw-rounded-md
    tw-p-4
    tw-mb-4;

  a {
    @apply tw-font-semibold
      tw-underline #{!important};
  }

  &__body {
    @apply tw-flex
      tw-items-start;
  }

  &__icon {
    @apply tw-flex-shrink-0
      tw-mr-3;

    svg {
      @apply tw-text-white
        md:tw-w-6
        md:tw-h-6;
    }
  }

  &__content {
    @apply tw-flex-1
      md:tw-flex
      md:tw-flex-col
      md:tw-justify-between
      tw-text-sm
      md:tw-text-base;

    &-title {
      @apply tw-font-semibold
        tw-mb-1;
    }

    p {
      @apply last:tw-mb-0;
    }
  }

  &--info {
    @apply tw-bg-blue-50;

    .hui-alert__icon {
      svg {
        @apply tw-text-blue-800;
      }
    }

    .hui-alert__content {
      @apply tw-text-blue-800;
    }

    a {
      @apply tw-text-blue-800 #{!important};
    }
  }

  &--danger {
    @apply tw-bg-red-50;

    .hui-alert__icon {
      svg {
        @apply tw-text-red-800;
      }
    }

    .hui-alert__content {
      @apply tw-text-red-800;

      p {
        @apply tw-text-sm;
      }
    }

    a {
      @apply tw-text-red-800 #{!important};
    }
  }

  &--success {
    @apply tw-bg-green-50;

    .hui-alert__icon {
      svg {
        @apply tw-text-green-800;
      }
    }

    .hui-alert__content {
      @apply tw-text-green-800;
    }

    a {
      @apply tw-text-green-800 #{!important};
    }
  }

  &--warning {
    @apply tw-bg-amber-50;

    .hui-alert__icon {
      svg {
        @apply tw-text-amber-800;
      }
    }

    .hui-alert__content {
      @apply tw-text-amber-800 #{!important};
    }

    a {
      @apply tw-text-amber-800 #{!important};
    }
  }
}
