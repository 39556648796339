.invoice-quote-email-text {
  @apply tw-text-sm
    tw-text-gray-700
    tw-mb-4;
}

.invoice-quote-email-link {
  @apply tw-underline
    tw-text-blue-600
    hover:tw-text-blue-600;
}

.invoice-quote-email-sign {
  @apply tw-text-sm
    tw-text-brand
    tw-font-bold;
}

.invoice-quote-email-text--disabled {
  .invoice-quote-email-text {
    @apply tw-opacity-50;
  }

  .invoice-quote-email-link {
    @apply tw-opacity-50;
  }

  .invoice-quote-email-sign {
    @apply tw-opacity-50;
  }
}
