.hui-tooltip {
  @apply tw-inline-block
    tw-rounded-full;
  @apply focus-visible:tw-shadow-none
    focus-visible:tw-outline
    focus-visible:tw-outline-2
    focus-visible:tw-outline-offset-0
    focus-visible:tw-outline-brand-200 #{!important};

  &-wrapper {
    @apply tw-bg-brand
    tw-text-white
    tw-rounded-md
    tw-shadow-sm
    tw-px-4
    tw-py-3
    tw-text-left
    tw-text-sm
    tw-font-light
    tw-flex
    tw-flex-col
    tw-max-w-[250px]
    tw-z-50;

    b, strong {
      @apply tw-font-medium;
    }

    a {
      @apply tw-mt-2
        tw-font-semibold
        tw-text-white
        hover:tw-text-white;
    }
  }

  svg {
    @apply hover:tw-cursor-pointer
      hover:tw-text-brand-900
      tw-text-brand-100;
  }
}

.hui-tooltip[data-floating-ui-inert] {
  svg {
    @apply tw-text-brand;
  }
}

.hui-tooltip:not([data-floating-ui-inert]) {
  svg {
    @apply tw-text-gray-300
      hover:tw-text-brand;
  }
}
