.hnry-textarea {
  @apply tw-block
    tw-box-border
    tw-min-h-[5em]
    tw-w-full
    tw-rounded-md
    tw-border-0
    tw-py-2
    tw-px-3
    tw-text-gray-900
    tw-text-sm
    tw-shadow-sm
    tw-ring-1
    tw-ring-inset
    tw-ring-gray-300
    placeholder:tw-text-gray-300
    disabled:tw-text-gray-500
    disabled:tw-cursor-not-allowed
    read-only:tw-bg-gray-50
    read-only:tw-text-gray-600
    invalid:tw-ring-red-500
    in-range:tw-bg-green-500
    tw-duration-300;
  @apply focus:tw-ring-1
    focus:tw-ring-inset
    focus:tw-ring-brand-200
    focus:tw-outline-none
    focus:tw-border-b-0 #{!important};
}

.hnry-textarea--invalid {
  @apply tw-ring-red-500
    focus:tw-ring-2
    focus:tw-ring-inset
    focus:tw-ring-red-500;
}

.hnry-error,
.hnry-note {
  @apply tw-text-gray-500
    tw-font-light
    tw-text-xs
    tw-mt-1
    tw-mb-3;
}

.hnry-error {
  @apply tw-text-red-500;
}

.hnry-label.hnry-label--required,
.required-if {
  @apply after:tw-content-['*']
    after:tw-inline
    after:tw-text-red
    after:tw-ml-1;
}
