.hui-link {
  @apply tw-text-brand
    tw-underline
    tw-font-semibold
    hover:tw-text-brand
    focus:tw-outline-brand-200
    active:tw-bg-brand-50;

  main p > &,
  section p > & {
    @apply tw-underline
      hover:tw-no-underline;
  }

  & svg {
    @apply tw-inline-block
      tw-align-sub
      tw-text-inherit
      tw-mr-2;
  }

  &--danger {
    @apply tw-text-red-600
      hover:tw-text-red-600
      focus:tw-outline-red-200
      active:tw-bg-red-50;
  }

  &--inverted {
    @apply tw-text-white
      hover:tw-text-white
      focus:tw-outline-white
      active:tw-bg-white;
  }

  &--info {
    @apply tw-text-blue-800
      hover:tw-text-blue-800
      focus:tw-outline-blue-200
      active:tw-bg-blue-50;
  }
}

