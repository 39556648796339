.hr {
  @apply tw-my-3
  tw-h-px
  tw-bg-gray-200
  tw-border-0;
}

.loading-skeleton:empty {
  @apply tw-h-8
    tw-w-full
    tw-rounded-md
    tw-bg-gray-200
    tw-animate-pulse;
}

.v-space {
  @apply tw-flex 
    tw-flex-col 
    tw-space-y-4 
    md:tw-space-y-8;
}

/*
 *  Ice Cream Loader with Hnry Colours
 *  by: https: //cssloaders.github.io/
 */
.loader-icecream {
  height: 150px;
  width: 100px;
  border-radius: 55px 55px 10px 10px;
  position: relative;
  background: #FF3D00;

  background-image: linear-gradient(0deg,
      #4FA7CF 0%,
      #4FA7CF 20%,
      #33CC94 20%,
      #33CC94 40%,
      #F9D44C 40%,
      #F9D44C 60%,
      #E9986A 60%,
      #E9986A 80%,
      #E172A1 80%,
      #E172A1 100%);
  background-position: 0 0;
  background-size: auto 175px;
  background-repeat: repeat-y;
  animation: colorShift 6s linear infinite;
  transform: translateY(-20px);


  &::before {
    content: '';
    position: absolute;
    left: 10px;
    bottom: 15px;
    width: 15px;
    height: 100px;
    border-radius: 50px;
    background: rgba(255, 255, 255, 0.5);
  }

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translate(-50%, 0);
    box-shadow: 0 15px 2px rgba(0, 0, 0, 0.25) inset;
    width: 32px;
    height: 45px;
    background: #E9986A;
    border-radius: 0 0 12px 12px;
  }
}

@keyframes colorShift {
  to {
    background-position: 0 175px
  }
}
