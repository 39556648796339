.hui-progress-bar {
  &__icon {
    @apply tw-text-white
      tw-space-y-8
      tw-items-center
      tw-justify-center;
  }

  &__list-item {
    @apply tw-relative
      tw-flex
      tw-items-start;
  }

  &__indicator {
    @apply tw-flex
      tw-items-center;

    &-item {
      @apply tw-relative
        tw-flex
        tw-h-8
        tw-w-8
        tw-items-center
        tw-justify-center
        tw-rounded-full
        tw-border-2
        tw-border-brand-green-600
        tw-bg-white;

      &.complete {
        @apply tw-bg-brand-green-600;
      }

      &.incomplete {
        @apply tw-border-brand-green-300;
      }
    }

    &-circle {
      @apply tw-h-2.5
        tw-w-2.5
        tw-rounded-full
        tw-bg-transparent;

      &.current {
        @apply tw-bg-brand-green-600;
      }
    }
  }

  &__line-wrapper {
    @apply tw-inset-0
      tw-flex
      tw-items-center
      tw-w-full;
  }

  &__line {
    @apply tw-h-0.5
      tw-w-full
      tw-px-4
      tw-bg-brand-green-300
      tw-space-x-8;

    &.complete {
      @apply tw-bg-brand-green;
    }
  }

  &__line--vertical {
    @apply tw-absolute
      tw-left-4
      tw-top-4
      tw--ml-px
      tw-mt-0.5
      tw-h-full
      tw-w-0.5
      tw-bg-brand-green-300;

    &.complete {
      @apply tw-bg-brand-green;
    }
  }

  &__content {
    @apply tw-flex
      tw-flex-col
      tw-text-sm;
  }

  &__title {
    @apply tw-font-bold
      tw-text-gray-700;

    &.current {
      @apply tw-text-brand-green-800;
    }
  }

  &__subtext {
    @apply tw-text-gray-600;
  }
}
