.hnry-pagination {
  @apply tw-isolate 
    tw-inline-flex 
    tw--space-x-px 
    tw-rounded-md 
    tw-shadow-sm 
    tw-my-4
    tw-ring-1 
    tw-ring-inset 
    tw-ring-gray-300;

  .hnry-pagination-gap {
    @apply tw-relative 
      tw-inline-flex
      tw-items-center
      tw-text-sm
      tw-px-3
      tw-py-2
      tw-cursor-default
      tw-ring-1
      tw-ring-inset
      tw-ring-gray-300
      tw-text-gray-800;
  }

  a,
  .hnry-pagination-link {
    @apply tw-relative
      tw-inline-flex 
      tw-items-center
      tw-text-sm
      tw-px-4 
      tw-py-2
      tw-cursor-pointer
      tw-ring-1 
      tw-ring-inset 
      tw-ring-gray-300
      tw-text-gray-800 
      hover:tw-text-white 
      hover:tw-bg-brand;
    
    svg {
      @apply tw-text-inherit;
    }

    &.disabled {
      @apply tw-text-gray-300 
        tw-cursor-not-allowed
        tw-ring-1 
        tw-ring-inset 
        tw-ring-gray-300
        hover:tw-bg-white;
    }

    &:first-child {
      @apply tw-px-2 tw-rounded-l-md sm:tw-px-3;
    }

    &:last-child {
      @apply tw-px-2 tw-rounded-r-md sm:tw-px-3;
    }

    &.current {
      @apply tw-bg-brand tw-text-white hover:tw-bg-brand;
    }

    .label {
      @apply tw-text-sm 
        tw-font-medium;
      
      &.prev {
        @apply tw-pl-0 
          sm:tw-pl-2 
          tw-sr-only
          sm:tw-not-sr-only;
      }

      &.next {
        @apply tw-pr-0 
          sm:tw-pr-2
          tw-sr-only
          sm:tw-not-sr-only;
      }
    }
  }
}

