.hnry-button {
$self: &;

  &#{$self}--danger {
    @apply tw-bg-red-600
      hover:tw-bg-red-500
      hover:tw-text-white
      disabled:tw-bg-red-600/disabled
      disabled:tw-text-white/disabled;

    &.hnry-button--loading {
      @apply

        hover:tw-bg-red-600
        after:tw-text-white;
    }

    svg, i.fa, img {
      @apply tw-text-white;
    }

    &:disabled {
      svg, i.fa, img {
        @apply tw-text-white/disabled;
      }
    }

  }
}