// Old styles

.hnry-label--legacy {
  @apply tw-block
  tw-text-xs
  tw-leading-6
  tw-text-gray-700
  -tw-mb-2
  tw-relative;
}

/* TODO: Remove input[type=text] when MDB is finally gone */
input[type=text].hnry-input--legacy {
  @apply tw-w-full
    tw-border-b
    tw-px-0
    tw-pt-0.5
    tw-pb-2
    tw-leading-5
    md:tw-text-base
    tw-text-gray-800
    focus:tw-ring-0
    focus:tw-outline-0
    focus-visible:tw-outline-0
    focus:tw-border-brand-green-300 #{!important};

    &.no-border {
      @apply tw-border-b-0 focus:tw-border-b-0 #{!important};
    }
}

/* TODO: Remove this dirty hack when MDB is finally gone */
html > body input[type=text].no-bs,
html > body input[type=number].no-bs,
html > body input[type=date].no-bs,
html > body input[type=email].no-bs {
  border-bottom: 0 !important;
  box-sizing: border-box !important;

  @apply tw-h-10
    focus:tw-ring-1
    focus:tw-ring-inset
    focus:tw-ring-brand-200
    focus:tw-outline-none
    focus:tw-border-b-0 #{!important};
}

html > body input[type=text].hnry-comboxbox-with-clear {
  @apply tw-pr-[4.5rem] #{!important};
}

html > body input[type=text].hnry-comboxbox {
  @apply tw-pr-10 #{!important};
}

html > body input[type=text].no-bs-no-ring,
html > body input[type=number].no-bs-no-ring,
html > body input[type=date].no-bs-no-ring,
html > body input[type=email].no-bs-no-ring {
  @apply tw-h-10
  tw-border-b-0
  tw-box-border
  focus:tw-border-b-0 #{!important};
}

/* TODO: Remove this dirty hack when MDB is finally gone (to show the input border) */
html > body input[type=text].hnry-input--invalid,
html > body input[type=number].hnry-input--invalid,
html > body input[type=date].hnry-input--invalid,
html > body input[type=email].hnry-input--invalid {
  @apply tw-ring-red-500
    focus:tw-ring-2
    focus-visible:tw-ring-inset
    focus-visible:tw-ring-red-500 #{!important};
}

html > body input[type=text].hnry-input-copy {
  @apply tw-min-h-[2.5rem]
    tw-box-border #{!important};
}

/*
 * New styles
 * We need to adjust specificity of these to work with MDB
*/
.hnry-label {
  @apply tw-block
    tw-text-sm
    tw-font-medium
    tw-leading-6
    tw-text-gray-700
    tw-mb-1;

  &.hnry-label--required {
    @apply after:tw-content-['*']
      after:tw-inline
      after:tw-text-red
      after:tw-ml-1;
  }

  .hui-tooltip {
    @apply tw-ml-1;
  }
}

.hnry-input {
  @apply tw-block
    tw-box-border
    tw-min-h-[2.5rem]
    tw-w-full
    tw-rounded-md
    tw-border-0
    tw-py-1.5
    tw-px-3
    tw-text-gray-900
    tw-shadow-sm
    tw-ring-1
    tw-ring-inset
    tw-ring-gray-300
    placeholder:tw-text-gray-300
    disabled:tw-text-gray-500
    disabled:tw-cursor-not-allowed
    focus:tw-ring-1
    focus:tw-ring-inset
    focus:tw-ring-brand-100
    focus:tw-outline-none
    read-only:tw-bg-gray-50
    read-only:tw-text-gray-600
    sm:tw-text-sm
    sm:tw-leading-6 #{!important};

  &__right-icon {
    @apply tw-pointer-events-none
      tw-cursor-default
      tw-absolute
      tw-inset-y-0
      tw-right-0
      tw-flex
      tw-items-center
      tw-pr-3;
  }

  &__right-icon--clickable {
    @apply tw-pointer-events-auto
    tw-cursor-pointer
  }
}

// Styles to apply to .hnry-input without an !important flag
// stylelint-disable-next-line no-duplicate-selectors
.hnry-input {
  @apply tw-truncate
    focus:tw-text-clip
}

input[type=number].hnry-input {
  -moz-appearance: textfield !important;
}

.hnry-input--invalid {
  @apply tw-ring-red-500
    focus:tw-ring-2
    focus:tw-ring-inset
    focus:tw-ring-red-500;
}

.hui-input-price {
  @apply tw-relative
    tw-flex
    tw-flex-grow
    tw-items-stretch
    focus-within:tw-z-10;

  &__currency {
    @apply tw-pointer-events-none
      tw-absolute
      tw-inset-y-0
      tw-left-0
      tw-flex
      tw-items-center
      tw-pl-3;

    span {
      @apply tw-text-gray-500
        sm:tw-text-sm;
    }
  }

  .hnry-input {
    @apply tw-py-1.5 #{!important};

      &.hnry-currency-sign {
        @apply tw-pl-8 #{!important};
      }
  }
}

.search-input .hnry-input {
  @apply tw-pr-10 #{!important}
}
