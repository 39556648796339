.hui-tab-wrapper {
  @apply tw-border-b
    tw-border-gray-200
    tw-scroll-mt-14
    sm:tw-scroll-mt-0;
}

.hui-tabs {
  @apply -tw-mb-px
    tw-flex
    tw-space-x-4
    md:tw-space-x-8
    tw-overflow-x-auto
    tw-justify-evenly
    sm:tw-justify-normal;

  &--centered {
    @apply tw-justify-evenly
      tw-gap-x-4;
  }

  &__tab {
    @apply tw-border-transparent
      tw-text-gray-500
      hover:tw-border-gray-300
      hover:tw-text-gray-700
      tw-whitespace-nowrap
      tw-border-b-2
      tw-py-4
      md:tw-px-1
      tw-px-0
      tw-font-medium
      tw-text-base;

    &--active {
      @apply tw-border-blue-600
        tw-text-blue-600
        hover:tw-border-blue-600
        hover:tw-text-blue-600;
    }
  }
}
