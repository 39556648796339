.hnry-more-actions {
  @apply tw-relative
    tw-inline-block
    tw-text-left;
}

.hnry-more-actions__menu-button {
  @apply tw-flex
    tw-items-center;

  svg {
    @apply tw-w-6
      tw-h-6;
  }
}

.hnry-more-actions__menu-items {
  @apply focus:tw-border-brand-400
    focus:tw-outline-none
    tw-rounded-md
    tw-absolute
    tw-whitespace-nowrap
    tw-right-0
    tw-z-20
    tw-mt-2
    tw-origin-top-right
    tw-bg-gray-50
    tw-shadow-lg
    tw-ring-1
    tw-ring-gray-950
    tw-ring-opacity-5;

  &--left {
    @apply tw-right-auto;
  }
}
