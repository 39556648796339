.hnry-button {
  $self: &;

  &#{$self}--large {
    @apply tw-text-lg
      tw-px-6
      tw-py-3;

    svg, i.fa, img {
      @apply tw-mr-3;
    }

    &.hnry-button--icon-end {
      svg, i.fa, img {
        @apply tw-ml-3;
      }
    }
  }

  &#{$self}--small,
  &#{$self}--tiny {
    @apply tw-min-w-8
      sm:tw-min-w-16
      tw-text-xs;
    @apply tw-px-2
      sm:tw-px-4
      tw-py-2.5 #{!important};

    svg, i.fa, img {
      @apply tw-mr-1;
    }

    &.hnry-button--icon-end {
      svg, i.fa, img {
        @apply tw-ml-1;
      }
    }
  }

  &#{$self}--tiny {
    @apply tw-px-3
      tw-py-1 #{!important};
  }

  &#{$self}--mob-full {
    @apply tw-w-full sm:tw-w-auto;
  }

  &#{$self}--right-end {
    @apply tw-pr-0 #{!important};
  }
}
