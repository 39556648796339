.hnry-button {
  $self: &;

  &--secondary {
    @apply tw-text-brand-700
      tw-bg-brand-25
      tw-ring-1
      tw-ring-inset
      tw-ring-brand-50
      hover:tw-bg-brand-50
      hover:tw-text-brand-600
      focus:tw-outline-brand-100
      disabled:tw-bg-brand-25/disabled
      disabled:tw-text-brand-600/disabled;

    svg, i.fa, img {
      @apply tw-text-brand-700;
    }

    &:disabled {
      svg, i.fa, img {
        @apply tw-text-brand-600/disabled;
      }
    }

    &.hnry-button--loading {
      @apply 
        hover:tw-bg-brand-25
        after:tw-text-brand-700;
    }

    &#{$self}--invert {
      @apply 
        tw-bg-brand-600
        tw-ring-brand-500
        tw-text-white
        hover:tw-bg-brand-800
        disabled:tw-text-white/disabled
        disabled:tw-bg-brand-600/disabled;

      &#{$self}--loading {
        @apply hover:tw-bg-brand-600;
      }

      svg, i.fa, img {
        @apply tw-text-white;
      }

      &:disabled {
        svg, i.fa, img {
          @apply tw-text-white/disabled;
        }
      }
    }
  }
}