.hnry-button {
  $self: &;

  @apply tw-rounded-lg
    tw-inline-block
    tw-py-2.5
    tw-px-6
    tw-font-medium
    tw-tracking-normal
    tw-normal-case
    tw-text-sm
    tw-text-center
    tw-h-fit
    tw-transition-colors
    tw-cursor-pointer
    max-sm:tw-w-full
    tw-whitespace-nowrap
    disabled:tw-cursor-not-allowed;

    // Following is needed to show focus for uploading brand logo in settings
    @apply focus-within:tw-shadow-none
      focus-within:tw-outline
      focus-within:tw-outline-2
      focus-within:tw-outline-offset-2
      focus-within:tw-outline-brand-200;

  // !important due to bootstrap overriding our styles
  @apply focus-visible:tw-shadow-none
    focus-visible:tw-outline
    focus-visible:tw-outline-2
    focus-visible:tw-outline-offset-2
    focus-visible:tw-outline-brand-200
    #{!important};

  svg, i.fa, img {
    @apply tw-mr-2
      tw-inline-block
      tw-align-text-top
      tw-h-[1.25em]
      tw-w-[1.25em];
  }

  &.hnry-button--icon-end {
    svg, i.fa, img {
      @apply tw-ml-2 tw-mr-0;
    }
  }

  svg {
    @apply tw-align-text-bottom;
  }

  i.fa {
    @apply tw-align-baseline;
  }

  &.hnry-button--loading {
    @apply hover:tw-shadow-none
      tw-text-transparent;

    &::after {
      @apply tw-rounded-lg tw-text-white;
    }

    &#{$self}--invert {
      @apply tw-text-transparent;
    }
  }

  &--primary,
  &--secondary,
  &--tertiary,
  &--danger {
    @apply tw-shadow-sm;
  }

  &--primary,
  &--danger {
    @apply tw-text-white;
  }

  &--link {
    @apply tw-text-brand-600
      hover:tw-text-brand-800
      disabled:tw-text-brand-500/disabled;

    &#{$self}--invert {
      @apply hover:tw-text-white disabled:tw-text-white/disabled;
    }

    &.hnry-button--loading::after {
      @apply tw-text-brand-700;
    }
  }

  /*
  * hnry-button--loading can be applied both, to the button, or an element inside the button.
  * This is so we can use Rails UJS which updates button content when a data-disable-with attribute is set.
  * https://api.rubyonrails.org/v4.0/classes/ActionView/Helpers/FormTagHelper.html#method-i-button_tag
  * However, it can only update button content when submitting, not classes
  * eg.
  * <button class="hnry-button hnry-button--primary hnry-button--loading">Submit</button>
  * or
  * <button class="hnry-button hnry-button--primary"><span class="hnry-button--loading">Submit</span></button>
  */
  &#{$self}--loading {
    @apply tw-relative
      tw-text-transparent
      hover:tw-text-transparent
      hover:tw-cursor-wait;

    svg, i, img {
      @apply tw-invisible;
    }

    &::after {
      @apply tw-w-1.5
        tw-h-1.5
        tw-absolute
        tw-rounded-full
        -tw-mt-0.5
        -tw-ml-3
        tw-left-1/2
        tw-top-1/2
        tw-text-white;

      content: "";
      animation: loaderAnimation 0.4s linear infinite alternate;
    }
  }

  &#{$self}--secondary,
  &#{$self}--tertiary,
  &#{$self}--link {
    @apply after:tw-text-brand-600;
    &#{$self}--invert {
      @apply after:tw-text-white;
    }
  }

}

@keyframes loaderAnimation {
  0% {
    box-shadow: -0.5rem -0.3rem, 0.5rem 0.3rem, 1.5rem -0.3rem;
  }

  50% {
    @apply tw-h-2.5 tw-opacity-80;
  }

  100% {
    box-shadow: -0.5rem 0.3rem, 0.5rem -0.3rem, 1.5rem 0.3rem;
  }
}

/* TODO: Remove once we don't need to !important our css classes anymore and instead apply a tw-text-white utility class */
.hnry-button.hnry-button--link--white {
  @apply tw-text-white
    hover:tw-text-white;
}

/* @TODO remove this once we can safely add in print media class via tailwinds */
@media print {
.hide-button-on-print {
    display: none;
  }
}
