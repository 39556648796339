.hui-table-wrapper {
  @apply tw-bg-white
    tw-rounded-md
    tw-flex
    tw-items-center
    tw-flex-col
    tw-mb-4;

  &.hui-stacked-list-wrapper {
    @apply tw-rounded-none
      sm:tw-rounded-md;
  }
}

.hui-table {
  @apply tw-min-w-full
    tw-shadow
    tw-leading-normal
    tw-divide-y
    tw-divide-gray-100
    tw-rounded-md
    tw-text-left;

  thead {
    @apply tw-bg-gray-50;
  }

  th {
    @apply tw-px-2
      xs:tw-px-3
      md:tw-px-5
      tw-py-3
      md:tw-py-4
      tw-align-bottom;
    @apply tw-text-gray-700
      tw-text-sm
      tw-font-medium #{!important};

    a.sortable:link,
    a.sortable:visited {
      @apply tw-text-gray-700;
    }

    &.admin-view {
      @apply tw-bg-admin-200;
    }
  }

  tr {
    @apply tw-border-b
      tw-border-gray-200
      tw-text-gray-500
      tw-font-light;
    @apply tw-text-xs
      sm:tw-text-sm
      hover:tw-bg-gray-50 #{!important};

    &.admin-view {
      @apply tw-bg-admin-100
        hover:tw-bg-admin-50;
    }
  }

  td {
    @apply tw-px-2
      xs:tw-px-3
      md:tw-px-5
      tw-py-3
      md:tw-py-4
      tw-text-xs
      sm:tw-text-sm
      tw-text-gray-600
      tw-font-light
      tw-break-words
      first:tw-text-gray-700
      first:tw-font-medium;

    .clickable-link {
      @apply tw-text-inherit;
    }

    &.admin-view {
      @apply tw-bg-admin-100;
    }

    &:first-child {
      a {
        @apply tw-font-medium
          tw-text-gray-700;
      }
    }
  }

  .clickable-row {
    &.col-warning-text {
      a {
        @apply tw-text-red-600;
      }
    }

    &.col-bold-text {
      a {
        @apply tw-text-gray-700
          tw-font-medium
          sm:tw-font-light
          sm:tw-text-gray-500;
      }
    }
  }
}
