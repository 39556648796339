$progress-bar-width: var(--progress-bar-percentage);

.progress-bar-wrapper {
  @apply tw-p-1
    tw-bg-gray-100
    tw-rounded-full
    tw-w-full
    tw-h-6
    tw-shadow-md
    tw-shadow-gray-300;
}

.progress-bar {
  @apply tw-h-4
    tw-rounded-full
    tw-ease-linear
    tw-duration-300
    tw-transition-width
    tw-transition-colors;
}

.progress-bar-moved .progress-bar {
  @apply tw-bg-brand-green;

  width: $progress-bar-width;
  animation: progressBarAnimation 1s;
}

@keyframes progressBarAnimation {
  0% {
    @apply tw-w-[5%] tw-bg-brand-green;
  }

  100% {
    @apply tw-bg-brand-green;

    width: $progress-bar-width;
  }
}
