.hnry-switch {
  @apply tw-relative
    tw-inline-flex
    tw-h-6
    tw-w-11
    tw-flex-shrink-0
    tw-cursor-pointer
    tw-rounded-full
    tw-border-2
    tw-border-transparent
    tw-transition-colors
    tw-duration-200
    tw-ease-in-out
    focus:tw-outline-none
    focus:tw-ring-2
    focus:tw-ring-brand-green-500
    focus:tw-ring-offset-2;

  &-container {
    @apply tw-flex
      tw-items-center;
  }

  &-toggle {
    @apply tw-pointer-events-none
      tw-inline-block
      tw-h-5
      tw-w-5
      tw-transform
      tw-rounded-full
      tw-bg-white
      tw-shadow
      tw-ring-0
      tw-transition
      tw-duration-200
      tw-ease-in-out;
  }

  &-label {
    @apply tw-font-medium
      tw-text-gray-500
      tw-text-sm
      sm:tw-text-base
      tw-ml-3;
  }


  &--stacked {
    @apply tw-flex-col-reverse;

    .hnry-switch-label {
      @apply tw-block
        tw-text-sm
        tw-leading-6
        tw-text-gray-700
        tw-mb-1
        tw-ml-0;
    }

    .hnry-switch-input {
      @apply tw-h-10
        tw-flex
        tw-items-center;
    }
  }
}

