.hui-layout {
  // the padding and width of the sidebar-offset and content-width are related so
  // when one of them is updated, both classes should be updated
  &--sidebar-offset {
    @apply lg:tw-pl-60
      2xl:tw-pl-72;
  }

  &--content-width {
    @apply lg:tw-w-60
      2xl:tw-w-72;
  }
}
