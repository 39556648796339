.hui-card {
  @apply tw-bg-white
    tw-rounded-md
    tw-shadow-lg;

  &-heading {
    @apply tw-flex
      tw-justify-between
      tw-border-b
      tw-border-gray-200
      tw-bg-white
      tw-pb-2
      tw-mb-3;

    &__title {
      @apply tw-font-semibold
        tw-text-lg
        tw-leading-8
        tw-text-gray-900
        tw-mb-0;
    }

    &__actions {
      @apply tw-flex
        tw-ml-4
        tw-flex-shrink-0;

      .hnry-button {
        @apply tw-min-w-min;
      }
    }
  }

  &-empty {
    @apply tw-text-center
      tw-flex
      tw-flex-col
      tw-items-center
      tw-my-6;

    &__title {
      @apply tw-text-gray-900
        tw-text-base
        tw-font-semibold
        tw-mt-4;
    }

    &__subtext {
      @apply tw-text-gray-600
        tw-text-sm
        tw-px-4
        tw-font-light
        tw-mt-1;
    }
  }
}
