.hui-sca {
  @apply tw-bottom-1/4
    md:tw-bottom-auto;
  
  .hnry-dialog-panel-header {
    @apply tw-flex
      tw-flex-col
      tw-items-center;
  }

  &__header-icon {
    @apply tw-inline-flex
    tw-h-12
    tw-w-12
    tw-items-center
    tw-justify-center
    tw-rounded-full;
  }

  &__success-icon {
    @apply tw-h-6
      tw-w-6
      tw-stroke-green-700;

    path {
      transform-origin: 50% 50%;
      stroke-dasharray: 48;
      stroke-dashoffset: 48;
      animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.5s forwards;
    }
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
