.hui-grid-list {
  @apply tw-grid
    tw-grid-cols-1
    sm:tw-grid-cols-2
    tw-mt-4
    tw-gap-4;

  li {
    @apply tw-flow-root
      tw-mb-3
      tw-mt-4;
  }

  &__item {
    @apply tw-relative
      tw-flex
      tw-items-center
      tw-space-x-4
      tw-rounded-xl
      tw-p-3
      hover:tw-bg-gray-50
      tw-items-start
      tw-min-h-full;

    &-icon {
      @apply tw-flex
        tw-h-16
        tw-w-16
        tw-flex-shrink-0
        tw-items-center
        tw-justify-center
        tw-rounded-lg;

      svg {
        @apply tw-inline
          tw-text-white;
      }
    }

    &-description {
      @apply tw-text-sm
        tw-mt-1
        tw-text-gray-700;
      @apply tw-mb-0 #{!important};
    }

    &-arrow {
      @apply tw-ml-auto #{!important};
    }
  }
}

.hui-stacked-list {
  @apply tw-w-full
    tw-divide-y
    tw-divide-gray-100
    tw-mb-0;

  li {
    @apply hover:tw-bg-gray-50;
  }

  &__item {
    @apply tw-flex
      tw-justify-between
      tw-gap-x-6
      tw-py-2
      tw-items-center;

    &-left {
      @apply tw-flex
        tw-flex-col
        tw-min-w-0
        tw-gap-x-4
        tw-flex-auto
        tw-grow
        tw-pl-2;
    }

    &-right {
      @apply tw-flex
        tw-items-end
        tw-gap-x-2;
    }
  }
}
