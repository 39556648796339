.hnry-button {
  $self: &;

  &#{$self}--link {
    @apply tw-p-0
      hover:tw-underline
      disabled:hover:tw-no-underline
      focus-visible:tw-underline;
    @apply focus-visible:tw-outline-none
      focus-visible:tw-shadow-none
      #{!important};

    &#{$self} svg {
      @apply tw-bg-brand-25
        tw-rounded-md
        tw-p-2
        tw-align-middle
        tw-h-[2.5em]
        tw-w-auto
        tw-align-[-0.9em];
    }

    &:hover svg {
      @apply tw-bg-brand-50;
    }

    &:focus-visible svg {
      @apply tw-outline
        tw-outline-2
        tw-outline-offset-2
        tw-outline-brand-200
        tw-bg-brand-50;
    }

    &.hnry-button--loading {
      @apply tw-text-brand-600/disabled
        hover:tw-text-brand-600/disabled
        hover:tw-no-underline;

      svg, i, img {
        @apply tw-visible tw-opacity-disabled;
      }
    }

    &:disabled {
        svg {
          @apply tw-text-brand/disabled;
        }
      }

    &#{$self}--invert {
      @apply tw-text-white
        disabled:tw-text-white/disabled
        disabled:tw-bg-brand-600/disabled;

      svg {
        @apply tw-bg-brand-600
         tw-text-white;
      }

      &:hover svg,
      &:focus-visible svg,
      &:focus svg {
        @apply tw-bg-brand-500;
      }

      &#{$self}--loading {
        @apply tw-text-white/20
          hover:tw-text-white/20
          after:tw-text-white;
      }

      &:disabled svg {
        @apply tw-text-white/disabled tw-bg-brand-600/disabled;
      }

      &:disabled:hover svg {
        @apply tw-text-white/disabled tw-bg-brand-600/disabled;
      }
    }

    &:disabled:hover svg {
      @apply tw-bg-brand-25;
    }
  }
}
