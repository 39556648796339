.page-title {
  @apply tw-text-gray-900
    tw-text-3xl
    tw-font-semibold;

  &__tooltip {
    @apply tw-text-xl #{!important};
  }
}

.empty-state__learn-more {
  &.tooltip-click-event {
    @apply tw-text-brand-600 #{!important};
  }
}
