.hnry-dialog {
  &-transition {
    @apply tw-fixed
      tw-inset-0
      tw-bg-black
      tw-bg-opacity-30
      tw-backdrop-blur-sm
      tw-transition-opacity
      tw-z-10;
  }

  &-panel {
    @apply
      tw-z-10
      tw-transform
      tw-rounded-lg
      tw-bg-white
      tw-px-4
      tw-pb-4
      tw-pt-5
      tw-text-left
      tw-shadow-xl
      tw-transition-all
      sm:tw-p-6
      tw-fixed
      tw-bottom-[0]
      sm:tw-bottom-auto
      sm:tw-top-1/2
      tw-left-1/2
      tw-max-h-[90vh]
      tw-w-[90vw]
      tw-max-w-[32rem]
      -tw-translate-x-1/2
      tw-translate-y-[-5vw]
      sm:-tw-translate-y-1/2
      focus:tw-outline-none
      tw-flex;

    &--double {
      @apply tw-max-w-[48rem];
    }

    &-content {
      @apply tw-text-left
        tw-overflow-y-auto
        tw-w-full
        tw-p-1;
    }

    &-header {
      @apply tw-flex
        tw-justify-between
        tw-items-center
        tw-mb-4;

      &__title {
        @apply tw-text-base
          sm:tw-text-xl
          tw-font-semibold
          tw-leading-6
          tw-text-gray-900
          tw-my-0
          tw-text-left
          tw-grow;
      }

      &__closable {
        @apply tw-flex
          tw-ml-4
          tw-flex-shrink-0;
      }
    }

    &-description {
      @apply tw-text-sm
        tw-text-gray-500;
    }

    &-actions {
      @apply tw-mt-4
        sm:tw-mt-8
        sm:tw-grid
        sm:tw-grid-flow-row-dense
        sm:tw-grid-cols-2
        sm:tw-gap-3
        sm:tw-flex-row-reverse;

      .hnry-dialog-panel--double & {
        @apply lg:tw-flex
          lg:tw-flex-row
          lg:tw-gap-3
          lg:tw-justify-between
          lg:tw-flex-row
          lg:tw-flex-row-reverse;
      }

      button,
      .hnry-button {
        @apply tw-mt-3
          tw-inline-flex
          tw-w-full
          tw-justify-center
          sm:tw-mt-0
          sm:tw-w-auto
          sm:tw-col-start-2;

        &:last-child {
          @apply sm:tw-col-start-1;
        }
      }

      &.closable {
        @apply tw-p-0
          tw-w-full
          tw-flex;

        button {
          @apply tw-w-full;
        }
      }
    }
  }
}
