.toastr-mount {
  @apply tw-flex
    tw-fixed
    tw-top-3
    tw-left-2/4
    -tw-translate-x-2/4
    tw-z-[9999]
    sm:tw-max-w-lg
    max-sm:tw-w-full
    tw-pl-1
    tw-pr-2;

  .toast {
    @apply tw-bg-white
      tw-flex
      tw-justify-center
      tw-my-0
      tw-mx-auto
      tw-flex-col
      tw-py-3
      tw-pl-3
      tw-pr-4
      tw-shadow-lg
      tw-border-solid
      tw-border-l-8
      tw-border-r-0
      tw-border-y-0
      tw-rounded-md;

    a {
      @apply tw-underline
        tw-text-blue-600;
    }

    &--success {
      @apply tw-border-l-green-400;
    }

    &--error {
      @apply tw-border-l-red-500;
    }

    &--warning {
      @apply tw-border-l-amber-500;
    }

    &--info {
      @apply tw-border-l-blue-500;
    }

    &-icon {
      &--success {
        @apply tw-text-green-400 #{!important};
      }

      &--error {
        @apply tw-text-red-500 #{!important};
      }

      &--warning {
        @apply tw-text-amber-500 #{!important};
      }

      &--info {
        @apply tw-text-blue-500 #{!important};
      }
    }

    &-icon-wrapper {
      @apply tw-self-start;
    }

    &-close-button {
      @apply tw-absolute
        tw-top-[0.66rem]
        tw-right-3
        tw-border-none
        tw-p-1
        tw-bg-white
        hover:tw-bg-gray-100
        hover:tw-cursor-pointer;
    }

    &-content {
      @apply tw-mx-2
        tw-text-sm
        tw-inline-block
        tw-text-gray-700;
    }

    &-message {
      @apply tw-flex
        tw-items-center
        tw-relative
        tw-pr-6;
    }

    &-toggle-more {
      @apply tw-p-0
        tw-ml-2
        tw-inline
        tw-text-left
        tw-text-sm
        tw-text-blue-600
        tw-underline;
    }
  }
}
