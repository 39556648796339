// Used to style native radio buttons like those in our RadioButtonList component.
// These can be found in the "Personal Bank Account" accordion
// under the "Financial" tab on the "Settings" page
.hnry-legacy-label {
  transform: translateY(-140%);

  @apply tw-absolute
    tw-text-gray-900
    tw-text-[0.8rem]
    tw-top-4;
}

.hnry-radio {
  @apply tw-w-0
    tw-h-0
    tw-opacity-0
    tw-appearance-none
    tw-z-0 #{!important};

  /* stylelint-disable-next-line no-descending-specificity */
  + label {
    @apply tw-flex
      tw-items-center
      tw-text-sm
      tw-cursor-pointer
      tw-ml-3
      tw-mr-4
      tw-pl-8 
      tw-relative 
      peer-disabled:tw-cursor-not-allowed #{!important};

    /* stylelint-disable-next-line no-descending-specificity */
    &:focus-visible {
      @apply tw-outline-none #{!important};
    }

    &::before,
    &::after {
      @apply tw-content-[''] 
        tw-rounded-[50%] 
        tw-absolute 
        tw-left-0
        tw-top-1/2
        tw-transition-none #{!important};
    }

    /* stylelint-disable-next-line no-descending-specificity */
    &::before {
      @apply tw-w-5
        tw-h-5
        tw-m-0
        tw-left-0
        -tw-translate-y-2/4 #{!important};
    }

    /* stylelint-disable-next-line no-descending-specificity */
    &::after {
      transform: scale(1.02) translateY(-50%) !important;

      @apply tw-mx-1
        tw-my-0
        tw-left-0.5 #{!important};
    }
  }

  &:focus-visible + label::before {
    @apply tw-shadow-none
      tw-outline
      tw-outline-2
      tw-outline-blue-200
      tw-outline-offset-2 #{!important};
  }

  /* stylelint-disable-next-line no-descending-specificity */
  &:not(:checked) + label {
    &::before {
      @apply tw-border
        tw-border-gray-200 #{!important};
    }

    &:hover::before {
      @apply tw-border-gray-400 #{!important};
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  &:checked + label {
    /* stylelint-disable-next-line no-descending-specificity */
    &::before {
      @apply tw-bg-blue-500 #{!important};
    }

    &::after {
      @apply tw-w-2
        tw-h-2
        tw-border-white
        tw-bg-white #{!important};
    }
  }

  &:not(:disabled) + label {
    @apply tw-text-gray-900 #{!important};
  }

  &:disabled {
    &:checked,
    &:not(:checked) {
      + label {
        @apply tw-cursor-not-allowed #{!important};

        &::before {
          @apply tw-border
            tw-border-gray-300
            tw-bg-gray-100 #{!important};
        }
      }
    }

    &:checked + label::after {
      @apply tw-border-gray-300
        tw-bg-gray-300 #{!important};
    }
  }
}
