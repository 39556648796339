.hnry-accordion {
  @apply tw-mb-6;

  $parent: &;

  &__button {
    @apply tw-flex 
      tw-justify-between 
      tw-rounded-md
      tw-w-full
      tw-text-base
      tw-py-4
      tw-px-5
      tw-text-left
      tw-border-none
      tw-font-semibold
      tw-bg-gray-100
      tw-text-gray-700
      tw-shadow
      tw-relative
      data-[state=open]:tw-rounded-b-none;

      #{$parent}__icon {
        @apply tw-transition-transform tw-scale-y-100;
      }

      &[data-state='open'] #{$parent}__icon {
        @apply -tw-scale-y-100;
      }
  }

  &__panel {
    @apply tw-rounded-md 
      tw-rounded-t-none 
      tw-bg-white 
      tw-border-solid 
      tw-border-t-0
      tw-shadow
      tw-text-gray-600
      data-[state=closed]:tw-animate-fadeOutUp
      data-[state=open]:tw-animate-fadeInDown;
  }
}
