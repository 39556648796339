.dashboard-modules-container {
  @apply tw-grid
    tw-grid-cols-1
    md:tw-grid-cols-2
    xl:tw-grid-cols-3
    tw-gap-y-4
    tw-gap-x-4
    xl:tw-gap-x-2
    2xl:tw-gap-x-4;
}

.module-header {
  @apply tw-flex
    tw-flex-row
    tw-justify-between
    tw-min-h-[50px]
    tw-bg-white
    tw-border-b-2
    tw-border-solid
    tw-border-gray-100
    tw-px-3.5
    tw-rounded-t-lg
    tw-pt-1;

  &--light {
    @apply tw-bg-gray-50;
  }
}

.module-title {
  @apply tw-text-xl
    tw-leading-10
    tw-font-semibold
    tw-float-left;
}
