@import "tailwindcss/base";
@import "tailwindcss/utilities";
@import "tailwindcss/components";

// Fonts
@import "https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Wallpoet&display=swap";

// helpers
@import "./helpers";
@import "./overrides";

// HTML only components
@import "./components/card";
@import "./components/empty_state";
@import "./components/lists";
@import "./components/pagination";
@import "./components/progress_bar";
@import "./components/radio_button";
@import "./components/table";
@import "./components/toast";

// React components
@import "../components/_atoms/badge/styles";
@import "../components/_atoms/button/styles";
@import "../components/_atoms/heading/styles";
@import "../components/_atoms/icons/styles";
@import "../components/_atoms/input/styles";
@import "../components/_atoms/link/styles";
@import "../components/_atoms/otp_input/styles";
@import "../components/_atoms/switch/styles";
@import "../components/_atoms/textarea/styles";
@import "../components/_molecules/alert/styles";
@import "../components/_molecules/tabs/styles";
@import "../components/_organisms/banner/styles";
@import "../components/_organisms/sca_confirm/styles";

// View, layout
@import "./dashboard";
@import "./layout";
@import "./invoice_quote";
